<template>
  <div  class="maincontent" >
      <NavBar
  title="套餐列表"
  left-arrow
  @click-left="$router.go(-1)"
  
/>
<!--@click-right="listOrders"
  right-text="订单" -->
  <van-card
        :num="p.num"
        :price="formatMoney(p.price/100)"
        :thumb="require('@/assets/healthpackages/a.jpg')"
        v-for="p in packages"
        :key="p.id"  
    >
 <template #title>
   <span class="packege_title" @click="showDetail(p.id)" >{{p.title}}</span>
  </template>
  <template #desc>
      <br>
   <span class="packege_desc">{{p.content}}</span>
  </template>
  <template #footer>
    <van-button size="small"   @click="chosePackage(p)" :type="getType(p)" >添加</van-button>
  </template>
</van-card>
 <br>
<div class="sp"></div>
<van-submit-bar :price="sum" button-text="提交订单" @submit="onSubmit" >
    <span class="selpackage">{{packagename}}</span>
    <template #tip >
       <a   >{{servertext}}</a><!--@click="showMyPackckages"  -->
    </template>
</van-submit-bar>
  </div>
</template>
<script>
import { Card as VanCard,Button as VanButton, SubmitBar as VanSubmitBar} from 'vant';
import {getPackages,createOrder,confirmOrder,queServerStatus} from '@/api/healthpackages';
import {getUser} from "@/api/user";
export default {
  name: 'PakageIdx',
  components: {
     VanCard,VanButton,VanSubmitBar
  }
  ,data(){
    return{
       packages:[
         
       ],
       packagename:'未选择',
       packageid:undefined,
       sum:0,
       serverstatus:false,
       servertext:'加载中...',
      user:{
        userName:'',
        nickName:'',
        idCard:'',
        phone:'',
        gender:'1',
        birthday:'',
        id:'',
        pid:''
      },
    }
  },mounted(){
       // this.serverStatus();
  },methods:{
    serverStatus(){
      queServerStatus({patientid:this.user.id}).then((response) => {
        console.log(response)
          if(response.code==200 ){
              this.serverstatus=response.data.serverstatus;
              this.servertext=response.data.servertext;
          }});
    },
    showMyPackckages(){
         this.$router.push({path:'/healthmanage/mypackages',query:{patientid:this.user.id}});
    },
    listOrders(){
         this.$router.push({path:'/healthmanage/orderlist',query:{patientid:this.user.id}});
    },
      initPackages(){
          getPackages().then((response) => {
            console.log(response)
          if(response.code==200 ){
              this.packages=response.data;
               this.$store.commit('updatePackages',this.builtMap(this.packages,'id',''));
          }
    });
      },
      onSubmit(){
        if(this.packageid){
            createOrder({pid:this.packageid,openid:this.$store.state.user.openid}).then((response) => {
              if(response.code ==200 ){
                  console.info(response.data);
                  //支付流程
                  this.handDataGetSuccess(response.data);
              }else{
                this.$toast.fail(response.data);
              }
          });
        } 
       // this.$router.go(-1)
      },
      showDetail(id){
          this.$router.push({path: '/healthmanage/packagedetailimg',query:{id:id}});
      },
      chosePackage(p){
          this.sum=p.price;
          this.packageid=p.id;
          this.packagename=p.title;
      },
      getType(p){
      if(p.id==this.packageid){
        return 'primary';
      }else{
        return 'default';
      }
    },
    handDataGetSuccess(data){
      var  vm=this;
      if (typeof window.WeixinJSBridge == "undefined"){
              if( document.addEventListener ){
                document.addEventListener('WeixinJSBridgeReady', vm.onBridgeReady, false);
              }else if (document.attachEvent){
                document.attachEvent('WeixinJSBridgeReady', vm.onBridgeReady); 
                document.attachEvent('onWeixinJSBridgeReady', vm.onBridgeReady);
              }
      }else{
        vm.onBridgeReady(data);
      }
    },
   onBridgeReady(data){
      var  vm=this;
      window.WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
         "appId":data.res.appId,     //公众号ID，由商户传入
         "timeStamp":data.res.timeStamp,         //时间戳，自1970年以来的秒数
         "nonceStr":data.res.nonceStr, //随机串
         "package":data.res.package?data.res.package:data.res.packageValue,
         "signType":data.res.signType,         //微信签名方式：
         "paySign":data.res.paySign //微信签名
      },
      function(res){
      if(res.err_msg == "get_brand_wcpay_request:ok" || res.err_msg == "get_brand_wcpay_request:fail" ){
        console.info();
          //后台确认订单状态
          confirmOrder({orderid:data.orderid}).then((response) => {
              if(response.code==200 ){
                   vm.$router.push({path:'/healthmanage/packageidx',query:{}});
                   vm.$toast.success('操作成功');
              }else{
                vm.$router.push({path:'/healthmanage/packageidx',query:{}});
                vm.$toast.fail('稍后进入订单管理页核实订单状态，不要重复发起支付');
              }
          });
      }else if(res.err_msg == "get_brand_wcpay_request:cancel"){
          vm.$router.push({path:'/healthmanage/packageidx',query:{}});
          vm.$toast.fail('支付取消');
      }
   }); 
  }
  },
  computed:{
    
  },
  created() {
    var query = this.$route.query;
    var code=query.code?query.code : this.getQueryString('code');
    var params={};
    if(code){
      params.code=code;
    }
    if(this.$store.state.user.openid){
      params.openid=this.$store.state.user.openid;
    }
    this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
       getUser(params).then((res) => {
          if(res.data){
            this.$store.commit('updateOpenid',res.data.openid);
            this.$store.commit('updatePid',res.data.id);
            this.$store.commit('updateBaseInfo',res.data);
            this.user.id=res.data.id;
            this.user.name=res.data.name;
            this.user.birthday=res.data.birthday;
            this.user.telephone=res.data.telephone;
            this.user.gender=res.data.gender+'';
            this.user.address=res.data.address;
            this.user.detail_address=res.data.detail_address;
            this.initPackages();
            this.serverStatus();
        }
      });
  }
}
</script>

<style scoped>
.maincontent{
padding-top: 0em;

}
.packege_title{
    font-size: medium;
}
.packege_desc{
    font-size: small;
    color:#646566;
     word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    -webkit-line-clamp: 2; 
    overflow: hidden; 
}
.selpackage{
    max-width: 35%;
}
.sp{
  height: 100px;
}

</style>
