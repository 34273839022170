
import request from '@/utils/request';


export function getPackages(){
      return request({
          url: '/healthmanage/package/list',
          method: 'post',
          params:{dataType:'noKey'}
        })
  
  }
  export function  queServerStatus(params){
    params.dataType='noKey';
    return request({
        url: '/healthmanage/package/queServerStatus',
        method: 'get',
        params:params
    })
}
export function createOrder(params){
    params.dataType='noKey';
    return request({
        url: '/healthmanage/package/createOrder',
        method: 'post',
        params:params
    })

}
export function  confirmOrder(params){
    params.dataType='noKey';
    return request({
        url: '/healthmanage/package/confirmOrder',
        method: 'get',
        params:params
    })
}












//下面是原来的
export function  listOrders(params){
    params.dataType='noKey';
    return request({
        url: '/patient_package_orders.json',
        method: 'get',
        params:params
    })
}

export function getPackageDetails(params,id){
    params.dataType='noKey';
    return request({
        url: '/packages/'+id+'.json',
        method: 'get',
        params:params
    })

}




export function  activatePackages(params){
    params.dataType='noKey';
    return request({
        url: '/patient_packages/get_my_packages.json',
        method: 'get',
        params:params
    })
}



export function getHP(params){
    params.dataType='noKey';
    return request({
        url: '/healthmanage/package/gethp',
        method: 'post',
        params:params
      })

}


export function  orderDetail(params){
  params.dataType='noKey';
  return request({
      url: '/healthmanage/package/orderDetail',
      method: 'post',
      params:params
    })
}


  